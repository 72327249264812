// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Open Sans", serif;
  src: url("../css/fonts/TTChocolates-BoldItalic.woff2") format("woff2"),
    url("../css/fonts/TTChocolates-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans", serif;
  src: url("../css/fonts/TTChocolates-ExtraBold.woff2") format("woff2"),
    url("../css/fonts/TTChocolates-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans", serif;
  src: url("../css/fonts/TTChocolates-DemiBoldIt.woff2") format("woff2"),
    url("../css/fonts/TTChocolates-DemiBoldIt.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Open Sans", serif;
  src: url("../css/fonts/TTChocolates-Regular.woff2") format("woff2"),
    url("../css/fonts/TTChocolates-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

* {
  transition: all 0.3s ease;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span {
  font-family: "Open Sans", serif;
}

:root {
  --bg-background: #f3f8ff;
  --bg-primary: #005f96;
  --bg-primary-dard: #012d46;
  --bg-primary-light: #00c2cb;
  --bg-yellow: #ed9325;
}

input, select, textarea, button {
  outline: none;
  padding: 0 5px;
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 43%);
  z-index: 99;
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1100px;
  }
}
